import merge from "deepmerge"
import defaultThemeColors from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors"

/*
 * Want to change your theme colors?
 * Try uncommenting the color overrides below
 * to go from default purple to a blue theme
 */

const darkBlue = `#0F2530`
const lightBlue = `#466B7D`
const blueGray = `#1F3D4D`
const orange = '#9D5420'

export default merge(defaultThemeColors, {
  text: blueGray,
  primary: darkBlue,
  heading: blueGray,
  highlight: orange,
  modes: {
    dark: {
      background: blueGray,
      primary: lightBlue,
      highlight: orange,
    },
  },
})
